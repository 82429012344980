<script lang="ts">
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import beranekImgSm from "../assets/images/easter/beranek.jpg?w=336&webp&imagetools";
	import beranekImgMd from "../assets/images/easter/beranek.jpg?w=550&webp&imagetools";
	import beranekImgLg from "../assets/images/easter/beranek.jpg?w=794&webp&imagetools";
	import bannerHeadlineSladkaImg from "../assets/images/sladka.svg";
	import bannerHeadlineDilnaImg from "../assets/images/dilna.svg";
	import cake from "../../../core/assets/icons/cake.svg?raw";
	import Link from "./Link.svelte";
	import { nbspify } from "../../../core/utils/nbspify";
	import { getProductPath } from "../../../core/schema/paths/getProductPath.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { categoryPathMap } from "../../../core/schema/paths/categoryPathMap";

	const { TENANT_LOCALE } = getGlobalSettings();
	const ilustrationProductLink = `/${getProductPath(TENANT_LOCALE, { name: { cs: "Velikonoční beránek" } })}`;
</script>

<div
	class="flex flex-col items-center gap-14 pb-20 pt-6 lg:flex-row lg:items-start lg:justify-center lg:gap-8 lg:pb-28 xl:pl-10"
>
	<div class="z-10 flex flex-col items-center text-center lg:basis-[calc(40%-1rem)] lg:items-start lg:text-left">
		<h1 class="m-0 pt-0 xl:pt-12">
			<span class="decorative-headline mb-8 block lg:text-left">Velikonoční</span>
			<span class="mb-8 flex flex-col items-center gap-4 sm:mb-10 sm:gap-[1.46rem] lg:items-start">
				<StaticPicture
					class="max-w-[19rem] sm:max-w-[25rem] lg:max-w-[23rem] xl:max-w-[30rem]"
					image={bannerHeadlineSladkaImg}
					alt="Nápis Sladká"
					height={185}
					width={465}
					loading="eager"
				/>
				<StaticPicture
					class="max-w-[15rem] sm:max-w-[21rem] lg:max-w-[19rem] xl:max-w-[26rem]"
					image={bannerHeadlineDilnaImg}
					alt="Nápis dílna"
					height={185}
					width={366}
					loading="eager"
				/>
			</span>
		</h1>
		<a href={ilustrationProductLink} class="contents">
			<StaticPicture
				image={beranekImgLg}
				alt="Ilustrační obrázek dortu"
				height={375}
				width={500}
				loading="eager"
				class="relative aspect-square h-[20rem] overflow-hidden rounded-lg sm:h-[25rem] lg:hidden"
				imgClass="absolute inset-0 h-full w-full"
				sourcesets={{
					"<md": beranekImgSm,
					"<lg": beranekImgMd,
				}}
			/>
		</a>

		<Link
			class="sm:!text-md mt-6 !rounded-full !px-8 !py-2 sm:mt-10 sm:!px-12 sm:!py-4 lg:order-2 xl:!py-[1.125rem] xl:!text-[1.375rem] 2xl:!px-20"
			href="/{categoryPathMap.easter[TENANT_LOCALE]}"
			icon={cake}
			iconClass="w-6"
			variant="primary"
			asButton
		>
			Velikonoční dobroty
		</Link>
		<p class="mt-6 lg:order-1 lg:mt-0">
			{nbspify(
				"Hody, hody, doprovody, skočte k nám pro vejce malovaný! Nebo pro vláčného beránka, bude se hodit z ránka. Beránek je podle receptu babičky Maru a v nabídce nechybí ani dokonalý mazanec plný rozinek a mandlí.",
			)}
		</p>
	</div>
	<a href={ilustrationProductLink} class="contents">
		<StaticPicture
			image={beranekImgLg}
			alt="Ilustrační obrázek dortu"
			height={595}
			width={794}
			loading="eager"
			class="relative hidden aspect-square overflow-hidden rounded-lg lg:mt-12 lg:block lg:h-[28rem] xl:mt-0 xl:h-[37.5rem]"
			imgClass="absolute inset-0 h-full w-full"
		/>
	</a>
</div>
